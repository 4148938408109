






import {Vue, Component} from 'vue-property-decorator';
import axios from "axios";
import {REQUESTS_W9CR_ENDPOINT} from "@/constants/endpoints";
import authHeader from "@/services/auth-header";

@Component({})
export default class MyPage extends Vue {

  resentSMS(){
    axios.put(`${REQUESTS_W9CR_ENDPOINT}/admin/resendsms`, {}, {headers: authHeader()});
  }

}
